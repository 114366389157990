import React from 'react';
import { Box, Typography } from '@mui/material';
import vision from '../../../../../assets/vision.jpeg'

const ValueProposition = () => {
    const videoUrl = 'https://lrnd-assets.s3.us-east-1.amazonaws.com/lrnd-product-video.mp4';
    const thumbnailUrl = 'https://lrnd-assets.s3.us-east-1.amazonaws.com/lrnd-video-poster.jpeg';

    return (
        <Box
            sx={{
                textAlign: 'center',
                padding: 8,
                color: '#333',
                // backgroundImage: `url(${vision})`,
            }}
        >
            <Typography variant="h3" component="h2" sx={{ fontWeight: 'bold', mb: 4 }}>
                Manage, Understand and Succeed
            </Typography>
            <Typography variant="h5" sx={{ lineHeight: 1.5, mb: 4 }} >
                Our AI-powered knowledge management tool revolutionizes the way you handle academic materials.
                Balancing academic and personal life has never been easier. Experience personalized learning,
                seamless document retrieval, and intuitive interaction, all in one state-of-the-art assistant.
            </Typography>
            {/*<Box*/}
            {/*    component="img"*/}
            {/*    src={vision} // Replace with the actual image path*/}
            {/*    alt="Value Proposition Illustration"*/}
            {/*    sx={{*/}
            {/*        maxWidth: '100%',*/}
            {/*        width: {xs: '100%', sm: '80%', lg: '50%'},*/}
            {/*        height: 'auto',*/}
            {/*        borderRadius: 4,*/}
            {/*        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',*/}
            {/*        mt: 4,*/}
            {/*    }}*/}
            {/*/>*/}

            <Box
                sx={{
                    maxWidth: '100%',
                    width: { xs: '100%', sm: '80%', lg: '60%' },
                    margin: 'auto',
                    mt: 4,
                    borderRadius: 4,
                    overflow: 'hidden',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
            >
                <video
                    controls
                    poster={thumbnailUrl}
                    style={{ width: '100%', borderRadius: '4px' }}
                >
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Box>
        </Box>
    );
};

export default ValueProposition;
